<template>
	<div class="m35 wrapper">
		<div class="password_txt input">
			<p>6자리 비밀번호를<br />등록해주세요.</p>
		</div>
		<div class="password-save">
			<ul class="pass-dots">
				<li v-for="(item, index) in displayCode" :key="index">
					<span></span>
				</li>
			</ul>
			<!-- 패스워드 오류문구 -->
			<p v-if="showNoMatch">등록한 비밀번호가 일치하지 않습니다.</p>
			<span v-if="showNoMatch">다시 입력해 주세요.</span>
			<!-- //패스워드 오류문구 -->
		</div>
		<ul class="password-board">
			<li @click="keyPadPressed(1)" class="board-num">1</li>
			<li @click="keyPadPressed(2)" class="board-num">2</li>
			<li @click="keyPadPressed(3)" class="board-num">3</li>
			<li @click="keyPadPressed(4)" class="board-num">4</li>
			<li @click="keyPadPressed(5)" class="board-num">5</li>
			<li @click="keyPadPressed(6)" class="board-num">6</li>
			<li @click="keyPadPressed(7)" class="board-num">7</li>
			<li @click="keyPadPressed(8)" class="board-num">8</li>
			<li @click="keyPadPressed(9)" class="board-num">9</li>
			<li @click="keyPadPressed(9999)" class="board-num">취소</li>
			<li @click="keyPadPressed(0)" class="board-num">0</li>
			<li @click="keyPadPressed(-1)" class="board-num">
				<img :src="require('@/assets/img/ic_back.png')" alt="" />
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'PassCode',

	data() {
		return {
			passCode: [],
			displayCode: [],

			showNoMatch: false,
		};
	},
};
</script>
