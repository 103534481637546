<template>
	<div class="m36 wrapper">
		<div class="bio-pass">
			<p>지문으로 인증해 주세요.</p>
			<button type="button" class="password-login" @click="doSwitchLoginType">
				비밀번호 로그인
			</button>
		</div>
		<div class="bio-img">
			<img :src="require('@/assets/img/btn_m_fingerprint.svg')" alt="지문" />
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
	name: 'LoginFingerPrint',

	created() {
		const payload = { type: 'finger', data: null };
		this.doLogin(payload);
	},

	methods: {
		...mapActions(['doLogin']),

		doSwitchLoginType() {
			this.$router.replace({
				path: '/',
				query: { LOGIN_YN: 'Y', FIDO_YN: 'N' },
			});
		},
	},
};
</script>
